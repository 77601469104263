<!-- 低代码平台 -->
<template>
  <div class="lvya-ddmpt">
    <div class="w100">
      <img src="static/lvya/ddmpt/lunbo5.jpg" alt="" style="width:100%;height: 100%;">
    </div>
    <div class="w100">
      <div class="w1200">
        <p class="title">针对教育场景的低代码开发平台</p>
        <p class="text-text">针对教育场景的低代码开发平台借助低代码工具，把教育应用场景的设计权和运营权充分赋能给学校；
        学校结合自身办学理念，精准定制适配所需应用场景，形成以用户主导应用和资源的教育闭环。通过可视化工具进行应用程序开发的方法，
        使具有不同经验水平的开发人员可以通过图形化的用户界面，使用拖拽组件和模型驱动的逻辑来创建网页和移动应用程序。</p>
        <img src="static/lvya/ddmpt/ddm01.png" alt="" class="wow animate__fadeInDown" data-wow-duration ="1.5s" data-wow-delay ="0s">
        <img src="static/lvya/ddmpt/ddm02.png" alt="" class="m30 wow animate__fadeInUp" data-wow-duration ="1.5s" data-wow-delay ="0s">
      </div>
    </div>
    <div class="bg100 w100">
      <div class="w1200">
        <p class="title">教育场景的落地应用</p>
        <p class="text-text">以中台技术实现教育行为和过程中产生的所有异构数据的有机整合和全量数据的打通，
        并形成统一的整体；所有教育过程性数据从采集、上传、分析、反馈评价到输出形成一套常态化且公平合理的运行机制。</p>
        <img src="static/lvya/ddmpt/ddm03.png" alt="" class="m50 wow animate__fadeInLeft" data-wow-duration ="1.5s" data-wow-delay ="0s">
      </div>
    </div>
    <div class="w100">
      <div class="w1200">
        <img src="static/lvya/ddmpt/ddm04.png" alt="" class="wow animate__fadeInUp" data-wow-duration ="1.5s" data-wow-delay ="0s">
        <img src="static/lvya/ddmpt/ddm05.png" alt="" class="t50 wow animate__fadeInUp" data-wow-duration ="1.5s" data-wow-delay ="0s">
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  }
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.lvya-ddmpt{
  .banner-top{
    margin-top: 60px;
  }
  .w100{
    width: 100%;
    .w1200{
      width: 1200px;
      margin: 0 auto;
      padding: 50px 0;
      text-align: center;
      img{
        width: auto;
        max-width: 100%;
      }
      .title{
        text-align: center;
        font-size: 24px;
        color: #333;
        font-weight: 600;
      }
      .text-text{
        margin-top: 20px;
        text-align: center;
        line-height: 30px;
        color: #666;
      }
      .p1{
        font-size: 24px;
        font-weight: 600;
        color: #333;
      }
      .green-line {
        width: 65px;
        height: 5px;
        background: #19ca81;
        margin: 10px 0 30px 0;
        border-radius: 2px;
      }
      .text-p {
        color: #555;
        line-height: 40px;
      }
      .m30{
        margin-top: 30px;
      }
      .m50{
        margin-top: 50px;
      }
      .t50{
        margin-top: -310px;
      }
    }
  }
  .bg100{
    background: #f9f9f9;
  }
}
</style>
